import React, { useState, useEffect, useRef } from 'react'
import parse from 'html-react-parser'

/* Local Types */
interface TextBannerWithHTMLProps {
	html?: string
	htmlID?: string
}

const TextBannerWithHTML = ({
	html,
	htmlID,
}: TextBannerWithHTMLProps): React.ReactElement | null => {
	/* HTML Ref */
	const ref = useRef<HTMLDivElement | null>(null)

	/* HTML State */
	const [canRender, setCanRender] = useState(false)
	const [newScript, setNewScript] = useState<HTMLScriptElement | null>(null)

	/* Render in client only */
	useEffect(() => {
		setCanRender(true)
	}, [])

	/* Replace script */
	useEffect(() => {
		if (!newScript && ref.current) {
			const scripts = ref.current.getElementsByTagName('script')
			Array.from(scripts).forEach((script) => {
				if (script.id === htmlID) {
					const newScript = document.createElement('script')
					Array.from(script.attributes).forEach((attr) =>
						newScript.setAttribute(attr.name, attr.value)
					)
					newScript.text = script.text
					script.parentNode?.replaceChild(newScript, script)
				}
			})
			setNewScript(newScript)
		}
	}, [newScript, canRender, html])

	return <div ref={ref}>{html && canRender && parse(html)}</div>
}

export default TextBannerWithHTML
