import React, { useEffect } from 'react'
import { useRouter } from '@core/navigation'

/* Local Types */
interface BtnClientProps {
	actions: { [key: string]: string }
}

export default function BtnClient({ actions }: BtnClientProps) {
	const router = useRouter()

	/* Attach click to buttons by id */
	useEffect(() => {
		const goToUrl = (url: string) => {
			if (url.indexOf('/') == 0) {
				router.push(url)
			} else {
				window.location.href = url
			}
		}
		const addRemoveListener = (add: boolean) => {
			Object.entries(actions).forEach(([id, url]) => {
				const btn = document.getElementById(id)
				if (btn) {
					if (add) {
						btn.addEventListener('click', () => {
							goToUrl(url)
						})
					} else {
						btn.removeEventListener('click', () => {
							goToUrl(url)
						})
					}
				}
			})
		}
		addRemoveListener(true)
		return () => addRemoveListener(false)
	}, [actions])

	return <></>
}
