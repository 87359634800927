import React from 'react'
import { useQueryString } from '@core/navigation'

/* PWA Helpers */
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

/* Hooks */
import { useNonBlockingPageCSS } from '@lib/hooks/useNonBlockingPageCSS'

export const withPageCSS = (
	id: string,
	children: React.ReactElement,
	LCP?: React.ReactElement | null,
	isLoader?: boolean,
	fillViewport?: boolean
): React.ReactElement => {
	/* Load CSS File for Server Debugging */
	const queryString = useQueryString()
	const isServerOnly: boolean =
		queryString.indexOf('__server_only') > -1 ? true : false

	/* Load Non-Blocking CSS File */
	const isMounted: boolean = isServerOnly || !id || useNonBlockingPageCSS(id)

	/* Gentle fade on css ready */
	const transitionStyles: React.CSSProperties = {
		transition: 'opacity 0.1s ease-in-out',
	}

	/* Nearly invisible until css ready */
	const layerStyles: React.CSSProperties = isLoader
		? {
				opacity: 1,
		  }
		: isMounted
		  ? {
					opacity: 1,
					visibility: 'visible',
		    }
		  : {
					opacity: 0.01,
					visibility: 'hidden',
		    }

	/* Anti-CLS */
	const heightStyles =
		isLoader || !isMounted || fillViewport
			? { minHeight: '125vh' }
			: { minHeight: 'auto' }

	return (
		<>
			{isServerOnly && (
				<link
					rel="stylesheet"
					href={getAssetUrl(`static/css/pages-${id}.css`)}
				/>
			)}
			{LCP || null}
			<div
				style={{
					...transitionStyles,
					...layerStyles,
					...heightStyles,
				}}
			>
				{children}
			</div>
		</>
	)
}
