import React from 'react'

/* Components */
import { Btn } from '@components/Btn/Btn'
import BtnClient from '@components/Btn/BtnClient'
import BrandLogo from '@components/logo/BrandLogo'
import InlineCSS from '@components/seo/InlineCSS'

/* Helpers */
import { getResource } from '@helpers/resourcesHelper'
import { routeContact, routeHome } from '@helpers/routeHelper'

/* Styles */
import SomethingWentWrongStyles from '!raw-loader!postcss-loader!sass-loader!./SomethingWentWrong.module.scss'

/* Local Types */
interface SomethingWentWrongResourcesType {
	h2: React.ReactElement | string
	body: React.ReactElement | string
	moreText: React.ReactElement | string
	btnContact: React.ReactElement | string
	btnHome: React.ReactElement | string
}

/* Variable config */
const SomethingWentWrongResources: SomethingWentWrongResourcesType = {
	h2: getResource('403', 'h2', `Something Went Wrong`),
	body: getResource(
		'403',
		'text',
		`We're sorry, an unexpected error occurred. Our team has been notified and is working on it.`
	),
	moreText: getResource('403', 'more-text', `Please try again shortly.`),
	btnContact: getResource('403', 'contact', `Contact Us`),
	btnHome: getResource('403', 'home', `Return to Home`),
}

const NotFound = (): React.ReactElement => {
	const buttonActions: {
		[key: string]: string
	} = {
		btnHome: routeHome(),
		btnContact: routeContact(),
	}

	return (
		<InlineCSS
			componentName={`SomethingWentWrong`}
			styles={SomethingWentWrongStyles}
		>
			<section className={'section'}>
				<div>
					<BrandLogo variant={`181x102`} />
				</div>
				<div>
					<h2 className={'section__h2'}>{SomethingWentWrongResources['h2']}</h2>
					<div className={'section__body'}>
						{SomethingWentWrongResources['body']}
					</div>
					<div className={'section__body'}>
						{SomethingWentWrongResources['moreText']}
					</div>
				</div>
				<div className={'section__btns'}>
					<Btn
						id={`btnContact`}
						variant={`primary`}
						className={'section__btns-btn'}
					>
						{SomethingWentWrongResources['btnContact']}
					</Btn>
					<Btn
						id={`btnHome`}
						variant={`secondary`}
						className={'section__btns-btn'}
					>
						{SomethingWentWrongResources['btnHome']}
					</Btn>
				</div>
			</section>
			<BtnClient actions={buttonActions} />
		</InlineCSS>
	)
}

export default NotFound
