import React from 'react'
import {
	StrapiHomepage,
	StrapiHomepageLocaleItem,
} from '@govberg/flageollet-types'

/* Helpers */
import {
	convertToArticlesBanner,
	convertToBrandBanner,
	convertToBrandsBanner,
	convertToHeroBanner,
	convertToImageBanner,
	convertToIntroBanner,
	convertToNewsBanner,
	convertToPlayBanner,
	convertToStoresBanner,
	convertToVideoBanner,
} from '@helpers/strapiHelper'
import { routeHome } from '@helpers/routeHelper'

/* Hooks */
import { useDetectHuman } from '@hooks/useDetectHuman'
import { useLazyLoadRestoreScroll } from '@hooks/useRestoreScroll'

/* Components */
import ArticlesBanner from '@components/banners/ArticlesBanner/ArticlesBanner'
import BrandBanner from '@components/banners/BrandBanner/BrandBanner'
import BrandsBanner from '@components/banners/BrandsBanner/BrandsBanner'
import HeroBanner from '@components/banners/HeroBanner/HeroBanner'
import ImageBanner from '@components/banners/ImageBanner/ImageBanner'
import IntroBanner from '@components/banners/IntroBanner/IntroBanner'
import PlayBanner from '@components/banners/PlayBanner/PlayBanner'
import StoresBanner from '@components/banners/StoresBanner/StoresBanner'
import TextBanner from '@components/banners/TextBanner/TextBanner'
import TextBannerWithHTML from '@components/banners/TextBanner/TextBannerWithHTML'
import VideoBanner from '@components/banners/VideoBanner/VideoBanner'
import NewsBanner from '../banners/NewsBanner/NewsBanner'
import CrumbsSchema from '@components/base/Crumbs/CrumbsSchema'
import HomeSchema from './HomeSchema/HomeSchema'

/* Contexts */
import { useCustomerContext } from '@context/customer'

/* Shared Types */
import { CrumbsType } from '@components/base/Crumbs/Crumbs.models'

const HomePage = ({ homepage }: { homepage?: StrapiHomepage }) => {
	const customerContext = useCustomerContext()
	const { locale } = customerContext[0]

	/* Render entire page on human context change */
	const isHuman = useDetectHuman()

	/* Restore scroll when component is fully rendered */
	useLazyLoadRestoreScroll()

	/* Strapi Locale */
	let supportedLocale: string
	switch (locale) {
		case 'en-us':
			supportedLocale = 'en'
			break
		case 'en-sg':
			supportedLocale = 'en-SG'
			break
		default:
			supportedLocale = 'en'
	}

	/* Local Components */
	const renderHomepage = () => {
		let localeSpecificBanner: StrapiHomepageLocaleItem | undefined

		if (homepage) {
			Object.entries(homepage).forEach((item) => {
				const [, entry] = item
				if (entry.locale === supportedLocale) {
					localeSpecificBanner = entry
					return
				}
			})
		}

		/* Render 3 max on initial load */
		const maxItems = isHuman ? 999 : 3

		return localeSpecificBanner?.content
			.slice(0, maxItems)
			.reduce((acc, curr) => {
				acc = acc || []

				switch (curr.__component) {
					case 'banners.hero-banner':
						if (curr.heroBannerSlides) {
							const convertedHeroSlides = convertToHeroBanner(
								curr.heroBannerSlides
							)

							acc.push(
								<HeroBanner
									key={curr.id + curr.__component}
									interval={curr.heroBannerIntervalTransitionSeconds || 0}
									slides={convertedHeroSlides}
								/>
							)
							if (curr.heroBannerBottomMargin) {
								acc.push(
									<div
										key={curr.id + curr.__component + 'bottom-margin'}
										style={{
											backgroundColor: 'white',
											height: curr.heroBannerBottomMargin,
										}}
									/>
								)
							}
						}
            return acc
					case 'banners.raw-html-banner':
						if (curr.rawHTMLBanner && curr.rawHTMLBanner.html) {
							acc.push(
								<TextBannerWithHTML
									key={curr.id + curr.__component}
									html={curr.rawHTMLBanner.html}
									htmlID={curr.rawHTMLBanner.htmlID}
								/>
							)
						}
						return acc
					case 'banners.text-banner':
						if (curr.textBannerText) {
							acc.push(
								<TextBanner
									key={curr.id + curr.__component}
									text={curr.textBannerText.text}
									mobiletext={curr.textBannerText.mobiletext}
									links={curr.textBannerText.addLinksToMatchingText?.links}
								/>
							)
						}
						return acc
					case 'banners.intro-banner':
						if (curr.introBannerItem) {
							const introBanner = convertToIntroBanner(curr.introBannerItem)
							acc.push(
								<IntroBanner
									key={curr.id + curr.__component}
									title={introBanner.title}
									main={introBanner.main}
									bullets={introBanner.bullets}
								/>
							)
							if (curr.introBannerBottomMargin) {
								acc.push(
									<div
										key={curr.id + curr.__component + 'bottom-margin'}
										style={{
											backgroundColor: 'white',
											height: curr.introBannerBottomMargin,
										}}
									/>
								)
							}
						}
						return acc
					case 'banners.video-banner':
						if (curr.videoBannerSlides) {
							const videoBannerSlides = convertToVideoBanner(
								curr.videoBannerSlides
							)

							if (videoBannerSlides.length > 0) {
								acc.push(
									<VideoBanner
										key={curr.id + curr.__component}
										slides={videoBannerSlides}
										desktopHeight={curr.videoBannerDesktopHeight || undefined}
										mobileHeight={curr.videoBannerMobileHeight || undefined}
									/>
								)

								if (curr.videoBannerBottomMargin) {
									acc.push(
										<div
											key={curr.id + curr.__component + 'bottom-margin'}
											style={{
												backgroundColor: 'white',
												height: curr.videoBannerBottomMargin,
											}}
										/>
									)
								}
							}
						}
						return acc
					case 'banners.play-banner':
						if (curr.playBannerItem) {
							const playBannerInfo = convertToPlayBanner(curr.playBannerItem)

							acc.push(
								<PlayBanner
									key={curr.id + curr.__component}
									info={playBannerInfo}
								/>
							)

							if (curr.playBannerBottomMargin) {
								acc.push(
									<div
										key={curr.id + curr.__component + 'bottom-margin'}
										style={{
											backgroundColor: 'white',
											height: curr.playBannerBottomMargin,
										}}
									/>
								)
							}
						}
						return acc
					case 'banners.image-banner':
						if (curr.imageBannerItem) {
							const imageBannerItem = convertToImageBanner(curr.imageBannerItem)

							acc.push(
								<ImageBanner
									key={curr.id + curr.__component}
									info={imageBannerItem}
								/>
							)

							if (curr.imageBannerBottomMargin) {
								acc.push(
									<div
										key={curr.id + curr.__component + 'bottom-margin'}
										style={{
											backgroundColor: 'white',
											height: curr.imageBannerBottomMargin,
										}}
									/>
								)
							}
						}
						return acc
					case 'banners.brand-banner':
						if (curr.brandBannerItem) {
							const brandBannerItem = convertToBrandBanner(curr.brandBannerItem)

							acc.push(
								<BrandBanner
									key={curr.id + curr.__component}
									info={brandBannerItem}
								/>
							)

							if (curr.brandBannerBottomMargin) {
								acc.push(
									<div
										key={curr.id + curr.__component + 'bottom-margin'}
										style={{
											backgroundColor: 'white',
											height: curr.brandBannerBottomMargin,
										}}
									/>
								)
							}
						}
						return acc
					case 'banners.brands-banner':
						if (curr.brandsBannerItems && curr.brandsBannerButtons) {
							const brandsBannerItems = convertToBrandsBanner(
								curr.brandsBannerItems,
								curr.brandsBannerButtons
							)

							acc.push(
								<BrandsBanner
									key={curr.id + curr.__component}
									infoItems={brandsBannerItems}
								/>
							)

							if (curr.brandsBannerBottomMargin) {
								acc.push(
									<div
										key={curr.id + curr.__component + 'bottom-margin'}
										style={{
											backgroundColor: 'white',
											height: curr.brandsBannerBottomMargin,
										}}
									/>
								)
							}
						}
						return acc
					case 'banners.articles-bnr':
						if (curr.articlesBannerSlides) {
							const articlesBannerSlides = convertToArticlesBanner(
								curr.articlesBannerSlides
							)

							if (articlesBannerSlides.length > 0) {
								acc.push(
									<ArticlesBanner
										key={curr.id + curr.__component}
										slides={articlesBannerSlides}
									/>
								)

								if (curr.articlesBannerBottomMargin) {
									acc.push(
										<div
											key={curr.id + curr.__component + 'bottom-margin'}
											style={{
												backgroundColor: 'white',
												height: curr.articlesBannerBottomMargin,
											}}
										/>
									)
								}
							}
						}
						return acc
					case 'banners.stores-bnr':
						if (curr.storesBannerSlides) {
							const storesBannerSlides = convertToStoresBanner(
								curr.storesBannerSlides
							)

							if (storesBannerSlides.length > 0) {
								acc.push(
									<StoresBanner
										key={curr.id + curr.__component}
										slides={storesBannerSlides}
									/>
								)

								if (curr.storesBannerBottomMargin) {
									acc.push(
										<div
											key={curr.id + curr.__component + 'bottom-margin'}
											style={{
												backgroundColor: 'white',
												height: curr.storesBannerBottomMargin,
											}}
										/>
									)
								}
							}
						}
						return acc
					case 'banners.news-banner':
						if (curr.newsBannerItems) {
							const newsBannerItems = convertToNewsBanner(curr.newsBannerItems)

							acc.push(
								<NewsBanner
									key={curr.id + curr.__component}
									items={newsBannerItems}
								/>
							)

							if (curr.newsBannerBottomMargin) {
								acc.push(
									<div
										key={curr.id + curr.__component + 'bottom-margin'}
										style={{
											backgroundColor: 'white',
											height: curr.newsBannerBottomMargin,
										}}
									/>
								)
							}
						}
						return acc
				}

				return acc
			}, [] as React.ReactElement[])
	}

	/* Crumbs */
	const crumbs: CrumbsType[] = [
		{
			name: 'Home',
			url: routeHome(),
		},
	]

	return (
		<>
			<CrumbsSchema crumbs={crumbs} />
			<HomeSchema />
			<div>{renderHomepage()}</div>
		</>
	)
}

export default HomePage
