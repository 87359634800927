import React from 'react'

/* Schema */
import { JsonLd } from 'react-schemaorg'
import { Organization } from 'schema-dts'

/* Global Config */
import { domain, siteName, ogLogo } from '@lib/config/global'

const HomeSchema = (): React.ReactElement => {
	return (
		<JsonLd<Organization>
			item={{
				'@context': 'https://schema.org',
				'@type': 'Organization',
				name: siteName,
				url: domain,
				logo: ogLogo,
				description:
					'Shop, trade & sell with one of the largest collections of new & used luxury watches online, The 1916 Company.',
				contactPoint: {
					'@type': 'ContactPoint',
					telephone: '855-906-5324',
					contactType: 'customer service',
					contactOption: 'TollFree',
					availableLanguage: 'en',
				},
				sameAs: [
					'https://www.instagram.com/the1916company/',
					'https://www.youtube.com/@the1916company',
					'https://www.facebook.com/the1916company/',
				],
			}}
		/>
	)
}

export default HomeSchema
